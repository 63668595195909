import React, { useState } from "react";
import axios from "axios";
import { Ring } from "react-awesome-spinners";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  CalendarIcon,
  TrashIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";

import TextError from "../../components/notifications/TextError";

import Success from "../../components/notifications/SuccessMessage";
import Error from "../../components/notifications/Error";
import Layout from "../../components/layout/layout";

import SEO from "../../components/seo";

const TaxForm = () => {
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");
  const [declare, setDeclare] = useState(false);

  const {
    register,
    handleSubmit,
    watch,

    formState: { errors, isDirty, isSubmitted, isSubmitting },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      honey: "",
      name: "",
      identification: "",
      email: "",
      contact: "",
      amount: "",
      transferdate:""
    },
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
  });

  // if (errors) {
  //   console.log("errors: ", errors);
  // }

  //   let count;

  //   if (watch("message")) {
  //     count = watch("message").length;
  //   } else {
  //     count = 0;
  //   }

  const onSubmit = async data => {
    if (data.honey !== "") {
      setErrorText("Spam suspected");
      return;
    }
    delete data.honey;
    console.log("submitted data: ", data);

    let airtableURL;
    let awsURL;

    if (data) {
      airtableURL = `${process.env.GATSBY_API_STATIC}/tax`;
      // airtableURL = `${process.env.REACT_APP_API_STATIC}/tax`;
    }
    try {
      setErrorText("");
      setLoading(true);
      // const res = await axios.post(awsURL, data);
      const res = await axios.post(airtableURL, data);
      console.log("tax res", res);
      if (res.data.statusCode === 400) {
        setErrorText(res.data.message);
        setLoading(false);
      } else if (res.data.statusCode === 200) {
        setResult("Submitted successfully");
        setLoading(false);
      }
    } catch (err) {
      setErrorText(err.message);
      setLoading(false);
    }
  };
  const declareHandler = e => {
    // console.log("agree:", e.target.checked);
    setDeclare(e.target.checked);
  };

  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO title="Tax Deductible Receipt Request" />

        <div className="mx-3 mb-12 space-y-8 divide-y divide-gray-200 md:mx-auto md:max-w-xl lg:max-w-2xl">
          <div className="px-1 pb-5 ">
            {/* <div className="mb-12">
              <h1 className="text-swa-3">Mailing Address</h1>
              <p className="max-w-2xl mt-1 text-gray-500">
                409 Serangoon Central #01-303 Singapore 550409
              </p>
            </div>
            <div className="mb-12">
              <h1 className="text-swa-3">Email</h1>
              <p className="max-w-2xl mt-1 text-gray-500">
                contactus@singaporewomenassociation.org
              </p>
            </div> */}
            <h1 className="text-swa-3">Tax Deductible Receipt request form</h1>
            <p className="max-w-2xl mt-1 text-gray-500">
              All cash donations will be going towards Singapore Women’s community
              projects. Each and every donation goes a long way and plays an
              integral part in assisting SWA and our beneficiaries.
            </p>
            <p className="max-w-2xl mt-1 text-gray-500">
              Your cash donation is tax deductible and qualifies for a tax deduction
              of 250%
            </p>
            <p className="max-w-2xl mt-1 text-gray-500">
              In compliance with the Personal Data Protection Act (PDPA), SWA to
              collect personal data for the purpose of issuing receipts, sending
              emails and other related donor management activities.
            </p>
            {/* <h2 className="text-red">
              Online enquiry form is temporary unavailable. Please email us
              directly at contactus@singaporewomenassociation.org
            </h2> */}
            {errorText && <Error msg={errorText} />}
            {isSubmitted && result && (
              <Success msg={result}>Submitted successfully</Success>
            )}
            {!isSubmitted && errorText === "" && result === "" && (
              <form
                className="max-w-xl mt-5 space-y-5"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                  <div className="pt-4 space-y-6 sm:pt-4 sm:space-y-5">
                    <div>
                      <h3 className="text-lg font-bold leading-6 text-swa-1">
                        Your information
                      </h3>
                    </div>
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="name"
                          className={
                            errors.name
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Name
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            {...register("name", {
                              required: true,
                              maxLength: 100,
                            })}
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                          />
                          {errors.name?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              A name is required
                            </p>
                          )}
                          {errors.name?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="identification"
                          className={
                            errors.identification
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Identification Number
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            {...register("identification", {
                              required: true,
                              maxLength: 100,
                            })}
                            // placeholder="optional"
                            type="text"
                            name="identification"
                            id="identification"
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                          />

                          {errors.identification?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="contact"
                          className={
                            errors.contact
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Contact number
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            {...register("contact", {
                              required: true,
                              maxLength: 20,
                            })}
                            id="contact"
                            name="contact"
                            type="text"
                            autoComplete="contact"
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                          />
                          {errors.contact?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              A contact number is required
                            </p>
                          )}
                          {errors.contact?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="email"
                          className={
                            errors.email
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Email address
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            {...register("email", {
                              required: true,
                              maxLength: 100,
                              pattern:
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-3 focus:border-swa-3 sm:"
                          />
                          {errors.email?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              Email address is required
                            </p>
                          )}
                          {errors.email?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                          {errors.email?.type === "pattern" && (
                            <span className="text-sm text-red-500">
                              Please enter a valid email format
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
                    {/* <h3 className="text-lg font-bold leading-6 text-swa-1">
                        Your message
                      </h3> */}
                    {/* <p className="max-w-2xl mt-1 text-gray-500">
                        Please provide at least one of your social media
                        references where appropriate or a link to your photo.
                        This is only for our initial assessment of you.
                      </p> */}

                    {/* <div className="grid-cols-1 sm:grid sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="message"
                        className={
                          errors.message
                            ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                            : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                        }
                      >
                        Message
                      </label>
                      <div className="relative mt-1 sm:mt-0 sm:col-span-2">
                        <textarea
                          {...register("message", {
                            required: true,
                            maxLength: 2000,
                          })}
                          id="message"
                          name="message"
                          rows={4}
                          className="block w-full pb-5 border border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3"
                          defaultValue={""}
                        />
                        {errors.message?.type === "required" && (
                          <p className="text-sm text-red-500 ">
                            A message is required
                          </p>
                        )}
                        {errors.message?.type === "maxLength" && (
                          <p className="text-sm text-red-500 ">
                            2000 max characters allowed
                          </p>
                        )}
                        <span className="absolute right-0 text-xs text-gray-400 -bottom-5">
                          count: {count && count} (max 2000 char)
                        </span>
                      </div>
                    </div> */}

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="amount"
                        className={
                          errors.amount
                            ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                            : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                        }
                      >
                        Amount Transferred
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          {...register("amount", {
                            required: true,
                            maxLength: 100,
                          })}
                          placeholder=""
                          type="text"
                          name="amount"
                          id="amount"
                          className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                        />

                        {errors.amount?.type === "maxLength" && (
                          <span className="text-sm text-red-500">
                            You have exceeded maximum length of 100 characters
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="transferdate"
                        className={
                          errors.transferdate
                            ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                            : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                        }
                      >
                        Date of transfer
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          {...register("transferdate", {
                            required: true,
                            maxLength: 100,
                          })}
                          placeholder=""
                          type="text"
                          name="transferdate"
                          id="transferdate"
                          className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                        />

                        {errors.transferdate?.type === "maxLength" && (
                          <span className="text-sm text-red-500">
                            You have exceeded maximum length of 100 characters
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="declaration"
                      name="declaration"
                      type="checkbox"
                      className="w-4 h-4 border-gray-300 rounded text-swa-1 focus:ring-swa-3"
                      onChange={e => declareHandler(e)}
                    />
                  </div>
                  <div className="ml-3 ">
                    <label
                      htmlFor="declarations"
                      className="font-medium text-gray-700"
                    >
                      Consent
                    </label>
                    <p className="text-gray-500">
                      In compliance with the Personal Data Protection Act
                      (PDPA), I hereby given consent to SWA to collect my
                      personal data for the purpose of issuing receipts, sending
                      emails and other related donor management activities.
                    </p>
                  </div>
                </div>
                <div className="pt-5">
                  <div className="flex justify-end">
                    {!isSubmitting && (
                      <button
                        type="submit"
                        disabled={
                          !isDirty ||
                          errors.name ||
                          errors.email ||
                          errors.contact ||
                          errors.amount ||
                          errors.transferdate ||
                          !declare
                        }
                        className="inline-flex justify-center px-4 py-2 ml-3 font-medium text-white border border-transparent rounded-md shadow-sm bg-swa-1 hover:bg-swa-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-swa-3 disabled:opacity-30"
                      >
                        Submit
                      </button>
                    )}
                    {loading && <Ring color="rgb(112,48,140)" />}
                    {errorText !== "" && <TextError>{errorText}</TextError>}
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default TaxForm;
